<template>
  <div class="User">

    <div class="new_user" v-if="$route.query.is_apply==1">
       新审核用户
    </div>

    <div class="actionBar">
      <a-space>
        <DepartmentSelect @DepartmentSelect="DepartmentSelect" :selectId="listParams.department_id" style="width:200px">
        </DepartmentSelect>
        <a-tree-select allowClear v-model="listParams.professional_id" style="width: 140px" :tree-data="professionalData"
          placeholder="职称" :replaceFields="{ title: 'name', value: 'id' }" />
        <a-tree-select allowClear v-model="listParams.check_status" style="width: 100px" :tree-data="check_status"
          placeholder="状态" />
        <a-range-picker style="width: 280px" v-model="time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :locale="locale">
        </a-range-picker>
        <a-input-search v-model="listParams.keyword" placeholder="请输入关键词搜索" enter-button="查询" style="width: 250px"
          allowClear @search="onSearch" />
        <a-button icon="download" type="primary" @click="downloadR"
          v-if="handLists.top ? handLists.top.indexOf('export') != -1 : false">
          导出结果</a-button>
      </a-space>
      <a-space>
        <a-button icon="plus" type="primary" @click="showModal"
          v-if="handLists.top ? handLists.top.indexOf('add') != -1 : false">
          添加用户</a-button>
      </a-space>
    </div>
    <a-modal :visible="previewVisible" @cancel="handleCancel2" :footer="null">
      <div style="width:400px ;height: 400px;">
        <img :src="previewImage" style="max-width: 100%;height: 400px;" />
      </div>
    </a-modal>
    <a-modal :title="modalTitle" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel" okText="确定" cancelText="取消">
      <a-form labelAlign="left" :label-col="{
        span: checkUserFlag ? 8 : 7,
        offset: checkUserFlag ? 1 : 3,
      }" :wrapper-col="{
  span: checkUserFlag ? 14 : 20,
  offset: checkUserFlag ? 1 : 3,
}" :from="addFromData">
        <a-row v-if="checkUserFlag">
          <a-col :span="12">
            <a-form-item label="账号">
              {{ addFromData.user_login }}
            </a-form-item>
            <a-form-item label="真实姓名">
              {{ addFromData.user_realname }}
            </a-form-item>
            <a-form-item label="手机号">
              {{ addFromData.mobile }}
            </a-form-item>
            <a-form-item label="科室">
              {{ addFromData.department_name }}
            </a-form-item>
            <a-form-item label="医师资格证">
              <img :src="addFromData.qualifications_url" alt="example" class="proveimg"
                @click="handlePreview(addFromData.qualifications_url)" v-if="addFromData.qualifications_url" />
              <img :src="addFromData.qualifications_two_url" alt="example" class="proveimg"
                @click="handlePreview(addFromData.qualifications_two_url)" v-if="addFromData.qualifications_two_url" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="昵称">
              {{ addFromData.user_nickname }}
            </a-form-item>
            <a-form-item label="性别">
              {{
                addFromData.sex == 1
                ? "男"
                : addFromData.sex == 2
                  ? "女"
                  : "保密"
              }}
            </a-form-item>
            <a-form-item label="医院">
              {{ addFromData.hospital }}
            </a-form-item>
            <a-form-item label="职称">
              {{ addFromData.professional_name }}
            </a-form-item>
            <a-form-item label="审核">
              <a-radio-group v-model="checkUserFromData.check_status" @change="checkstatusChange">
                <a-radio :value="4">
                  通过
                </a-radio>
                <a-radio :value="3">
                  不通过
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="原因" v-if="checkUserFromData.check_status == 3">
              <a-textarea v-model="checkUserFromData.reasons" :auto-size="{ minRows: 3, maxRows: 6 }" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-else>
          <a-col :span="12">
            <a-form-item label="账号">
              <a-input v-model="addFromData.user_login" />
            </a-form-item>
            <a-form-item label="真实姓名">
              <a-input v-model="addFromData.user_realname" />
            </a-form-item>
            <a-form-item label="手机号">
              <a-input-number style="width:200px" v-model="addFromData.mobile" />
            </a-form-item>
            <a-form-item label="科室">
              <DepartmentSelect @DepartmentSelect="DepartmentSelect2" :selectId="addFromData.department_id"
                style="width:200px"></DepartmentSelect>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="昵称">
              <a-input v-model="addFromData.user_nickname" />
            </a-form-item>
            <a-form-item label="性别">
              <a-radio-group button-style="solid" v-model="addFromData.sex">
                <a-radio-button :value="1">
                  男
                </a-radio-button>
                <a-radio-button :value="2">
                  女
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="医院">
              <a-input v-model="addFromData.hospital" />
            </a-form-item>
            <a-form-item label="职称">
              <a-tree-select allowClear v-model="addFromData.professional_id" :tree-data="professionalData"
                placeholder="请选择职称" :replaceFields="{ title: 'name', value: 'id' }" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="{
      total: count,
      current: listParams.page,
      pageSize: listParams.limit,
      showTotal: (total) => `共 ${total} 条`,
    }" @change="pageChange">
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>

      <span slot="check_status" slot-scope="text">
        <a-tag color="#999" v-if="text == 1">
          未验证
        </a-tag>
        <a-tag color="blue" v-else-if="text == 2">
          审核中
        </a-tag>
        <a-tag color="red" v-else-if="text == 3">
          不通过
        </a-tag>
        <a-tag color="green" v-else-if="text == 4">
          通过
        </a-tag>
      </span>

      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right ? handLists.right.indexOf('edit') != -1 : false">
          <FontIcon @click.native="editMenu(record)" title="修改" type="icon-xiugai" size="22"></FontIcon>
          <a-divider type="vertical" />
        </span>

        <a-switch checked-children="启" un-checked-children="禁" :checked="record.user_status == 1"
          @click="setStatus(record)" v-if="
            handLists.right ? handLists.right.indexOf('setStatus') != -1 : false
          " />

        <span v-if="
          record.check_status == 2 && handLists.right
            ? handLists.right.indexOf('check') != -1
            : false
        ">
          <a-divider type="vertical" />
          <FontIcon @click.native="checkUser(record)" title="审核" type="icon-shenhe" size="24"></FontIcon>
        </span>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  professionalShowList,
  userIndex,
  userDataSave,
  userSetStatus,
  userCheckUser,
  menuHandleList
} from "@/apis/index";
import { userExportResult } from "@/apis/down";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
  },
  {
    title: "账号",
    dataIndex: "user_login",
    scopedSlots: { customRender: "user_login" },
  },
  {
    title: "昵称",
    dataIndex: "user_nickname",
    scopedSlots: { customRender: "user_nickname" },
  },
  {
    title: "真实姓名",
    dataIndex: "user_realname",
    scopedSlots: { customRender: "user_realname" },
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "医院",
    dataIndex: "hospital",
    scopedSlots: { customRender: "hospital" },
  },
  {
    title: "科室",
    dataIndex: "department_name",
    scopedSlots: { customRender: "department_name" },
  },
  {
    title: "职称",
    dataIndex: "professional_name",
    scopedSlots: { customRender: "professional_name" },
  },
  {
    title: "审核状态",
    dataIndex: "check_status",
    scopedSlots: { customRender: "check_status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 200,
  },
];
const check_status = [
  {
    title: "全部",
    value: 0,
  },
  {
    title: "未验证",
    value: 1,
  },
  {
    title: "审核中",
    value: 2,
  },
  {
    title: "不通过",
    value: 3,
  },
  {
    title: "通过 ",
    value: 4,
  },
];

export default {
  data() {
    return {
      check_status,
      locale,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      addMenu: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        department_id: undefined,
        professional_id: undefined,
        check_status: undefined,
        start_time: "",
        end_time: "",
      },
      count: 0,
      autoExpandParent: true,

      // departmentData: [],
      professionalData: [],
      time: [],

      checkUserFlag: false,
      checkUserFromData: {},

      previewVisible: false,
      previewImage: "",
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },

  watch: {
    time(a) {
      if (a) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
    },
    handList(val) {
      this.handLists = val;
    },
    // 监听相同路由下参数变化的时候，从而实现异步刷新
    $route() {
      // 做一些路由变化的响应
      if (this.$route.query.is_apply == 1) {
        this.listParams.is_apply = 1
      } else {
        delete this.listParams.is_apply
      }
      this.getUserIndex();
    }
  },

  created() {
    if (this.$route.query.is_apply == 1){
      this.listParams.is_apply = 1
      menuHandleList({ id:3 }).then((res) => {
        this.$store.commit("setMenuHandleList", res.data);
      });
    } 
    this.getUserIndex();
    professionalShowList().then((res) => {
      this.professionalData = res.data.list;
    });
  },
  methods: {
    DepartmentSelect(val) {
      this.listParams.department_id = val;
    },
    DepartmentSelect2(val) {
      this.addFromData.department_id = val;
    },
    downloadR() {
      userExportResult(this.listParams).then((res) => {
        if (res.status == 200) {
          let blob = new Blob([res.data], {
            type: `application/csv`, //word文档为msword,pdf文档为pdf
          });
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          let fname = `用户列表.csv`; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute("download", fname);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    checkstatusChange(e) {
      if (e.target.value == 4) {
        this.checkUserFromData.reasons = "";
      }
    },

    handlePreview(url) {
      this.previewVisible = true;
      this.previewImage = url;
    },

    setStatus(record) {
      userSetStatus({
        id: record.id,
        status: record.user_status == 1 ? 0 : 1,
      }).then((res) => {
        if (res.code == 1) {
          this.getUserIndex();
        }
      });
    },
    showModal() {
      this.checkUserFlag = false;
      this.modalTitle = "新增用户";
      this.checkUserFlag = false;
      (this.addFromData = {
        id: "",
        user_login: "",
        user_realname: "",
        user_nickname: "",
        sex: 1,
        mobile: "",
        hospital: "",
        department_id: undefined,
        professional_id: undefined,
      }),
        (this.visible = true);
    },
    handleOk() {
      this.confirmLoading = true;
      if (this.checkUserFlag) {
        userCheckUser(this.checkUserFromData).then((res) => {
          if (res.code == 1) {
            this.$message.success("操作成功");
            this.getUserIndex();
            this.visible = false;
          }
          this.confirmLoading = false;
        });
      } else {
        userDataSave(this.addFromData).then((res) => {
          if (res.code == 1) {
            this.getUserIndex();
            this.$message.success("添加成功");
            this.visible = false;
          }
          this.confirmLoading = false;
        });
      }
    },
    handleCancel() {
      this.visible = false;
    },
    handleCancel2() {
      this.previewVisible = false;
    },
    editMenu(record) {
      this.checkUserFlag = false;
      this.modalTitle = "修改用户信息";
      this.addFromData = { ...record };
      this.visible = true;
      this.checkUserFlag = false;
    },
    checkUser(record) {
      this.modalTitle = "用户信息";
      this.addFromData = { ...record };
      this.checkUserFlag = true;
      this.visible = true;
      this.checkUserFromData = {
        id: record.id,
        check_status: 4,
        reasons: "",
      };
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getUserIndex();
    },
    getUserIndex() {
      userIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getRoleIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getUserIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.User {
  .new_user{
    font-weight: bold;
    font-size: 20px;
    padding-top:10px;
  }
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}

.proveimg {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
</style>
